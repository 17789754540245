@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#contact {
  height: 50vh;
}

#contact {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

#team {
  margin-top: 5%;
}

#process {
  margin-top: 5%;
}

.contactRow {
  display: flex;
  align-items: center; /* center <a> element vertically */
  justify-content: center; /* center <a> element horizontally */
}

.contactRow > a {
  transform: scale(1.5);
}

/* .innerContact {
  margin-top: 10%;
  margin-bottom: 50%;
} */

body {
  font-family: "Metropolis Regular";
  background: linear-gradient(180deg, #121212 19.28%, #0c0c0c 100%);
}

.header-bg {
  background: linear-gradient(
    89.75deg,
    #000000 0%,
    #555555 61.46%,
    #000000 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}

.logo-bg {
  height: 48px;
  width: 48px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: linear-gradient(89.75deg, #000000 0%, #6f6f6f 100%);
  background-color: #000;
  border: 1px solid;
  border-left-color: #6183e4;
  border-bottom-color: #6183e4;
  border-top-color: #37d5d6;
  border-right-color: #37d5d6;
}

.logo-bg:hover .logo-hover {
  opacity: 1;
}

.logo-bg .logo-hover {
  position: absolute;
  transition: 0.8s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 0;
  background: linear-gradient(89.75deg, #6f6f6f 0%, #000000 100%);
}

.mobile-menu-bg {
  background: url(./assets/images/mobileMenuBg.svg), #121212;
  background-position: center;
  background-size: cover;
}

.btn-gradient {
  background: linear-gradient(
    242.44deg,
    rgba(55, 213, 214, 0.75) 21.76%,
    rgba(97, 131, 228, 0.75) 77.02%
  );
  background-color: #37d5d6;
  transition: 0.5s;
}

.btn-gradient:hover {
  /* background: linear-gradient(242.44deg, rgba(55, 213, 214, 1) 21.76%, rgba(97, 131, 228, 1) 77.02%); */
  background-color: #000;
}

.text-gradiant {
  background: linear-gradient(
    242.44deg,
    rgba(55, 213, 214, 0.85) 21.76%,
    rgba(97, 131, 228, 0.85) 77.02%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .custom-card {
  min-height: 446px;
} */

.custom-toast .Toastify__toast--default {
  background: #21243e !important;
  color: rgba(255, 255, 255, 0.75) !important;
}

.custom-toast .Toastify__close-button--default {
  color: rgba(255, 255, 255, 0.75) !important;
}

.btn-gradient-border {
  border: 2px solid;
  border-image: linear-gradient(242.44deg, #37d5d6 21.76%, #6183e4 77.02%) 2;
  background: linear-gradient(242.44deg, #37d5d6 21.76%, #6183e4 77.02%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.btn-gradient-border:hover {
  background: linear-gradient(
    242.44deg,
    rgba(55, 213, 214, 0.75) 21.76%,
    rgba(97, 131, 228, 0.75) 77.02%
  );
  background-color: #37d5d6;
  color: #fff;
  font-weight: 500;
}

.btn-gradient-border-safari {
  border: 2px solid;
  /* border-image: linear-gradient(242.44deg, #37d5d6 21.76%, #6183e4 77.02%) 2; */
  /* background: linear-gradient(242.44deg, #37d5d6 21.76%, #6183e4 77.02%); */
  background-color: #37d5d6;
}

.hero-bg {
  background: url(./assets/images/Group6.png), #121212;
  background-position: center;
  background-size: 200% 100%;
  min-height: 100vh;
}

.custom-dot::after {
  content: "";
  background: #fff;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 50%;
}

.custom-line {
  position: absolute;
  left: 0;
  top: -12px;
  height: 2px;
  width: 100%;
  background: linear-gradient(
    242.44deg,
    rgba(55, 213, 214, 0.75) 21.76%,
    rgba(97, 131, 228, 0.75) 77.02%
  );
}

.custom-line-mobile {
  position: absolute;
  left: 0;
  top: 46%;
  height: 2px;
  width: 80%;
  background: linear-gradient(
    242.44deg,
    rgba(55, 213, 214, 0.75) 21.76%,
    rgba(97, 131, 228, 0.75) 77.02%
  );
}

.contact-bg {
  background: url(./assets/images/contactBg.svg), #121212;
  background-position: center;
  background-size: cover;
}

.max-w-33 {
  max-width: 100%;
}

#root {
  transition: all var(--transition-time-fast) var(--timing-f);
  filter: none;
}

body.ReactModal__Body--open {
  overflow-y: hidden;
}

body.ReactModal__Body--open > #root {
  filter: blur(2px);
}

body.ReactModal__Body--before-close > #root {
  filter: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@media only screen and (min-width: 640px) {
  .hero-bg {
    min-height: calc(100vh - 80px);
    background-size: 120% 100%;
  }
  .custom-line {
    /* width: 87%; */
    /* width: calc(150%); */
  }
  .max-w-33 {
    max-width: 33.33%;
  }
  .custom-card {
    min-height: 390px;
  }
}
